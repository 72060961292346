<section id="home" #home class="banner-area">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container-fluid">
                <div class="row align-items-center">
                    <div class="col-lg-6">
                        <div class="banner-content">
                            <h1>Software You Don't Have to Worry About&#8482;</h1>
                            <p>Mobile apps, web apps, custom apps — basically all apps.</p>
                            <div class="banner-btn">
                                <a (click)="scrollTo('contact')" class="box-btn">Contact Us</a>
                                <a (click)="scrollTo('solutions')" class="box-btn border-btn">Know More</a>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-6">
                    <div class="banner-img">
                            <img src="assets/images/computer.jpg" alt="banner-img" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="home-shape">
        <div class="shape1"><img src="assets/images/shape/1.png" alt="shape"></div>
        <div class="shape2"><img src="assets/images/shape/2.png" alt="shape"></div>
        <div class="shape3"><img src="assets/images/shape/3.png" alt="shape"></div>
        <div class="shape4"><img src="assets/images/shape/4.png" alt="shape"></div>
        <div class="shape5"><img src="assets/images/shape/5.png" alt="shape"></div>
        <div class="shape6"><img src="assets/images/shape/6.png" alt="shape"></div>
    </div>
</section>

<section id="solutions" #solutions class="home-service-area pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <span>Creative Solutions</span>
            <h2>Peace Builders Loves to Solve the Hard Problems</h2>
            <p>We think technology is an awesome tool that should 
                be used to solve the problems of the world. 
                We love partnering with businesses, charities, and individuals with a 
                passion to transform a problem into an opportunity.
            </p>
        </div>

        <div class="row">
            <div class="col-lg-4 col-sm-6">
                <div class="single-service">
                    <div class="service-content">
                        <h3>App Development</h3>
                        <p>The core of our business is built on quality app development. 
                          Our process includes designing a UI/UX your grandma could use with a code base made to last a couple centuries.
                          We also test our apps over and over, so you don't have a bunch of complaints from your end users. 
                          And we love to get stuff done. We're not interested in building an app for 2 years when 2 months will do.
                        </p>
                        <a (click)="scrollTo('contact')" class="line-bnt">Get Started</a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6">
                <div class="single-service">

                    <div class="service-content">
                        <h3>Venture Partner Program</h3>
                        <p>We love partnering with businesses that have a great idea for an app.
                            That's why we offer a program where we'll help you bootstrap
                            the app's development in exchange for a percentage of any profits the app may make. 
                            Don't feel like running a software business? No worries! We'll take your 
                            idea and run with it, while you get a nice cut of the profits. Did anyone say passive income?
                        </p>
                        <a (click)="scrollTo('contact')" class="line-bnt">Get Started</a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6">
                <div class="single-service">
                    <div class="service-content">
                        <h3>Charity Programs</h3>
                        <p>No one knows the problems facing our world like the charities on the frontlines. As part of our mission,
                            we are excited to open up space for charities to share how they think an app could impact our world for the better. 
                            As resources become available, we will do our
                            best to turn those world changing ideas into reality!
                        </p>
                        <a (click)="scrollTo('contact')" class="line-bnt">Get Started</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<section id="about" class="home-company-area bg-color">
    <div class="container">
        <div class="row align-items-center">

            <div class="col-lg-6 col-md-12">
                <div class="company-img">
                    <picture>
                        <img src="assets/images/fair.jpg" alt="Picture of the Owen County Fair from the top of a ferris wheel" type="img/jpg">
                    </picture>
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="company-content">
                    <div class="company-tittle">
                        <span>Our Company</span>
                        <h2>Big Solutions. Small Town Values.</h2>
                        <p>If you visit the town of Spencer (population just over 2,000), you'll find a fantastic coffee shop, a local diner, and a grocery store that's been around since 1928. And just a few miles outside of this charming town you will find our headquarters.</p>
                        <p>We like to think that our small town values have shaped our company for the better. Some of our core values include:</p>
                        <ul>
                            <li>We believe in hard work. We want to provide more value than we're getting paid.</li>
                            <li>Our initial response to a problem is "we can find a solution".</li>
                            <li>We want our clients happy. It makes running into them around town less awkward.</li>
                            <li>We love new technology (like AI), but you won't see it being used by us to replace humans in areas where humans are best.</li>
                            <li>We don't like delaying projects. We think the original timeline is something that should be kept sacred.</li>
                            <li>We are budget conscious. We don't like to nickel and dime our customers under the disguise of "change orders".</li>
                            <li>We do our best to treat each client the way we'd like to be treated.</li>
                        </ul>
                        <p>These values are what set us apart. And I suppose it's why big city companies use our small town development services.</p>
                        <p><i>P.S. Next time you're in town, let us know and we'll meet up for some coffee!</i></p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>


<section id="contact" #contact class="home-contact-area bg-color ptb-100">
    <div class="container">
        <div class="section-title">
            <span>Contact Us</span>
            <h2>Let Us Know About Your Project Or Idea!</h2>
        </div>
        <div *ngIf="showForm">
        <div class="row align-items-center">
            <div class="col-lg-12 col-md-12">
        <!--        <div id="hubspotForm"></div>-->
                <form class="contactForm" [formGroup]="contactForm">
                <div class="row align-items-center">
                <div class="col-lg-12 col-md-12">
                    <label for="contactName">Your Name:* 
                        <span class="errorMessage" *ngIf="submitted && contactForm.get('contactName').invalid">Name is required</span>
                    </label><br />
                    <input id="contactName" type="text" formControlName="contactName">
                    </div></div>
                <div class="row align-items-center">     
                    <div class="col-lg-6 col-md-6">
                        <label for="email">Email:* 
                            <span class="errorMessage" *ngIf="submitted && contactForm.get('contactEmail').invalid">Email is required</span>
                        </label><br />
                        <input id="email" type="text" formControlName="contactEmail">
                    </div>          
                <div class="col-lg-6 col-md-6">
                    <label for="phone">Phone:* 
                        <span class="errorMessage" *ngIf="submitted && contactForm.get('contactPhone').invalid">Phone is required</span>
                    </label><br />
                    <input id="phone" type="text" formControlName="contactPhone">
                </div>
            </div>
                <div class="row align-items-center">
                    <div class="col-lg-12 col-md-12">
                    <label for="message">Message:* 
                        <span class="errorMessage" *ngIf="submitted && contactForm.get('contactMessage').invalid">Message is required</span>
                    </label> <br />
                    <input id="message" type="text" formControlName="contactMessage">
                </div>
                <div class="col-lg-12 col-md-12">
                    <br />
                    <span class="errorMessage" *ngIf="submitted && contactForm.get('recaptchaReactive').invalid">Prove you're real:</span>
                <re-captcha formControlName="recaptchaReactive">
                </re-captcha>
                </div>
                </div>
                <div class="row align-items-center">
                    <div class="col-lg-12 col-md-12">
                    <div class="nav-btn">
                        <span>By submitting this form you agree with our terms and conditions.</span><br>
                        <button class="submitButton" class="box-btn submitButton" type="button" (click)="submitForm()">Submit</button>
                    </div>
                </div></div>
                </form>
            </div>
        </div>
    </div>
        <div *ngIf="successMessage">
            <div class="row align-items-center">
                <div class="col-lg-12 col-md-12">
                <h4 class="messageReceived">We got your message and will be in touch soon! Thank you!</h4>
                </div>
            </div>
        </div>
    </div>
</section>