import { Injectable } from '@angular/core';
import { collection, addDoc } from '@firebase/firestore';
import {
  Firestore,
} from '@angular/fire/firestore';

@Injectable({
  providedIn: 'root'
})
export class FirestoreService {

  constructor(private firestore: Firestore) {
  }

  async sendMessage(data){
    const messageList = collection(this.firestore, 'Messages');
    const newMessage = addDoc(messageList, data);
    return newMessage;
  }

}
