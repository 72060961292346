export const environment = {
  firebase:  {
    apiKey: "AIzaSyCQgzTVehQ0MP6fsIsW7OKZkrOZGom2utI",
    authDomain: "peacebuilders.firebaseapp.com",
    projectId: "peacebuilders",
    storageBucket: "peacebuilders.appspot.com",
    messagingSenderId: "741384774296",
    appId: "1:741384774296:web:bf5647e48a75b33def66ed",
    measurementId: "G-11JYQP59N7"
  },
  production: true,
  recaptcha: {
    siteKey: '6Lfm8JkeAAAAAL7uXTllmS3QggYHMwLKpQye73Tv',
     secret: '6Lfm8JkeAAAAALCg6MrMiuom3WDWDOONPPKE4Elu',
     v2key: '6LcFGSYqAAAAAByG0S7ZlyMDgZVwTpn19RyJ1rvv',
     v2secret: '6LcFGSYqAAAAAGFwfo0qBoVugY3ARrsces42RIXd'
  }
}
