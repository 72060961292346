<footer class="footer-area pt-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-md-6">
                <div class="content">
                    <div class="logo">
                        <a (click)="scrollTo('home')"><img src="assets/images/logoBlue.png" alt="logo" id="logoImage"/></a>
                    </div>
                    <p>Software You Don't Have to Worry About&#8482;</p>
                </div>
            </div>

            <div class="col-lg-3 col-md-6">
                <div class="content ml-15">
                    <h3>Our Service</h3>

                    <ul class="footer-list">
                        <li><a (click)="scrollTo('solutions')">App Development</a></li>
                        <li><a (click)="scrollTo('solutions')">UI/UX Design</a></li>
                        <li><a (click)="scrollTo('solutions')">QA & Testing</a></li>
                        <li><a (click)="scrollTo('solutions')">Venture Partnerships</a></li>
                        <li><a (click)="scrollTo('solutions')">Charity Projects</a></li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-2 col-md-6">
                <div class="content">
                    <h3>Quick Links</h3>
                    <ul class="footer-list">
                        <li><a (click)="scrollTo('contact')">Contact</a></li>
                        <li><a (click)="scrollTo('contact')">Career</a></li>
                        <li><a routerLink="/privacy-policy">Privacy & Policy</a></li>
                        <li><a routerLink="/terms-condition">Terms & Conditions</a></li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-3 col-md-6">
                <div class="content contacts">
                    <h3 class="ml-40">Contact</h3>

                    <ul class="footer-list foot-social">
                        <li><a href="tel:+18128217014"><i class="bx bx-phone"></i> +1 (812) 821-7014</a></li>
                        <li><a href="mailto:tech&#64;peachbuilders.net"><i class="bx bxs-envelope"></i> tech&#64;peacebuilders.net</a></li>
                        <li><i class="bx bxs-map"></i>Freedom, Indiana, USA</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>

    <div class="copy-area">
        <div class="container">
            <div class="row">
                <div class="col-lg-6">
                    <ul class="menu">
                        <li><a (click)="scrollTo('home')">Home</a></li>
                        <li><a (click)="scrollTo('about')">About</a></li>
                        <li><a (click)="scrollTo('solutions')">Solutions</a></li>
                        <li><a (click)="scrollTo('contact')">Contact</a></li>
                    </ul>
                </div>

                <div class="col-lg-6">
                    <p>© 2024 Peace Builders, a McNeil Enterprises, Inc. Company.</p>
                    Photo by <a href="https://unsplash.com/@wasdrew?utm_content=creditCopyText&utm_medium=referral&utm_source=unsplash">Andras Vas</a> on <a href="https://unsplash.com/photos/macbook-pro-turned-on-Bd7gNnWJBkU?utm_content=creditCopyText&utm_medium=referral&utm_source=unsplash">Unsplash</a>
                </div>
            </div>
        </div>
    </div>
</footer>

<div class="go-top">
    <i class='bx bx-chevrons-up'></i>
    <i class='bx bx-chevrons-up'></i>
</div>