import { Component, OnInit } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { ViewportScroller } from "@angular/common";
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ReCaptchaV3Service } from 'ng-recaptcha';
import { FirestoreService } from '../../../firestore.service';

@Component({
    selector: 'app-home-one',
    templateUrl: './home-one.component.html',
    styleUrls: ['./home-one.component.scss']
})
export class HomeOneComponent implements OnInit {

    public contactForm: FormGroup = new FormGroup({
        recaptchaReactive: new FormControl(null, Validators.required),
        contactName: new FormControl('', Validators.required),
        contactPhone: new FormControl('', Validators.required),
        contactEmail: new FormControl('', [Validators.required, Validators.email]),
        contactMessage: new FormControl('', Validators.required)
    });
    submitted: boolean;
    successMessage: boolean;
    showForm: boolean
    constructor(private scroller: ViewportScroller, private recaptchaV3Service: ReCaptchaV3Service,  private firestoreService: FirestoreService) { 
        this.submitted = false;
        this.successMessage = false;
        this.showForm = true;
    }
    ngOnInit(): void {
    }

    ngAfterViewInit(){
    }
    teamSlides: OwlOptions = {
		loop: true,
        margin: 20,
        dots: true,
        autoplay: true,
        autoplayHoverPause: true,
        responsive: {
            0: {
                items: 1,
            },
            575: {
                items: 2,
            },
            576: {
                items: 2,
            },
            768: {
                items: 3,
            },
            992: {
                items: 3,
            },
            1200: {
                items: 4,
            }
        }
    }
    clientWrap: OwlOptions = {
		loop:true,
		margin:30,
		nav:false,
		mouseDrag: true,
		items:1,
		dots: false,
		autoHeight: true,
		autoplay: true,
		smartSpeed: 800,
		autoplayHoverPause: true,
		center: false,
		responsive:{
			0:{
				items:1,
				margin: 10,
			},
			576:{
				items:1,
			},
			768:{
				items:2,
				margin: 20,
			},
			992:{
				items:2,
			},
			1200:{
				items:2,
			}
		}
    }

    // Accordion
    accordionItems = [
        {
            title: 'Great Understanding',
            content: `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt sit amet consectetur adipiscing.`,
            open: false
        },
        {
            title: 'Update Technology',
            content: `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt sit amet consectetur adipiscing.`,
            open: false
        },
        {
            title: 'Experienced Team',
            content: `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt sit amet consectetur adipiscing.`,
            open: false
        },
        {
            title: 'Best Quality Service',
            content: `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt sit amet consectetur adipiscing.`,
            open: false
        }
    ];
    selectedItem : any = null;
    toggleAccordionItem(item:any) {
        item.open = !item.open;
        if (this.selectedItem && this.selectedItem !== item) {
            this.selectedItem.open = false;
        }
        this.selectedItem = item;
    }
    
    // Tabs
    currentTab = 'tab1';
    switchTab(event: MouseEvent, tab: string) {
        event.preventDefault();
        this.currentTab = tab;
    }
    scrollTo(anchor) {
        this.scroller.scrollToAnchor(anchor);
    }

    submitForm(){
        this.submitted = true;
        console.log("done!");
        if(this.contactForm.valid){
            var d = new Date();
                var sendObject = {
                  to: "ethan@peacebuilders.net",
                  message: {
                    subject: "You have a new message from the website!",
                    text: "Here's your message: \n" +
                    "Name: " + this.contactForm.get('contactName').value + "\n" +
                    "Phone: " + this.contactForm.get('contactPhone').value + "\n" +
                    "Email:  " +  this.contactForm.get('contactEmail').value + "\n" +
                    "Message:  " + this.contactForm.get('contactMessage').value + "\n" +
                    "date:  " + d.getFullYear() + "-" + (d.getMonth() + 1) + "-" + d.getDate() + "\n"
                  }
                };
               this.firestoreService.sendMessage(sendObject).then( () => {
                this.contactForm.reset();
                this.successMessage = true;
                this.showForm = false;
               });
            }
    }


}