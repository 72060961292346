<header class="header-area">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-2 col-sm-0">
                <div class="logo">
                    <a routerLink="/"><img src="assets/images/webLogoBlue.png" alt="logo" /></a>
                </div>
            </div>

            <div class="col-lg-10 col-sm-6 text-end pr-0">
                <div class="header-content-right">
                    <ul class="header-contact">
                        <li><a href="tel:+18128217014"><i class="bx bxs-phone-call"></i> +1 (812) 821-7014</a></li>
                        <li><a href="mailto:tech&#64;peacebuilders.net"><i class="bx bxs-envelope"></i> tech&#64;peacebuilders.net</a></li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</header>

<div class="{{navbarClass}}">
    <div
        class="main-nav"
        [ngClass]="{'sticky': isSticky}"
    >
        <div class="container">
            <nav class="navbar navbar-expand-lg navbar-light" [class.active]="classApplied">
                <a class="navbar-brand d-none" routerLink="/">
                    <img src="assets/images/webLogoG.png" alt="logo">
                </a>
                <button class="navbar-toggler" type="button" (click)="toggleClass()">
                    <span class="burger-menu">
                        <span class="top-bar"></span>
                        <span class="middle-bar"></span>
                        <span class="bottom-bar"></span>
                    </span>
                </button>
                <div class="collapse navbar-collapse mean-menu" id="navbarSupportedContent">
                    <ul class="navbar-nav text-left">
                        <li class="nav-item">
                            <a (click)="scrollTo('home')" class="nav-link" >Home</a>
                        </li>
                
                        <li class="nav-item"><a (click)="scrollTo('solutions')" class="nav-link" >Solutions</a></li>
                      
                        <li class="nav-item">
                            <a (click)="scrollTo('about')" class="nav-link" >About</a>
                        </li>
 

                        <li class="nav-item"><a (click)="scrollTo('contact')" class="nav-link">Contact</a></li>
                    </ul>
                </div>
    
                <div class="nav-btn">
                    <a (click)="scrollTo('contact')" class="box-btn">Get Started</a>
                </div>
            </nav>
        </div>
    </div>
</div>